import { fromAsyncThrowable } from 'neverthrow'
import { Application, Assets, Container, Sprite } from 'pixi.js'

const PREPARING = ref(true)

const width = 130
const height = 130
const app = new Application()

app.init({
  width,
  height,
  resolution: 1.5,
  backgroundAlpha: 0,
}).finally(() => {
  PREPARING.value = false
})

export const getTinyFarmImg = fromAsyncThrowable(async (cropImage: string) => {
  await until(PREPARING).not.toBeTruthy()
  const container = new Container()
  app.stage.addChild(container)

  try {
    const backgroundTexture = await Assets.load('/image/farm/tiny.png')

    {
      const sprite = new Sprite(backgroundTexture)
      sprite.setSize(width, height)
      container.addChild(sprite)
    }

    const cropTexture = await Assets.load(cropImage)
    const createCrop = (...position: [number, number]) => {
      const cropSprite = new Sprite(cropTexture)
      cropSprite.setSize(22, 22)
      cropSprite.position.set(...position)
      container.addChild(cropSprite)
    }
    createCrop(width / 2 - 22 / 2, 25)
    createCrop(16, 48)
    createCrop(width - 16 - 22, 48)
    createCrop(width / 2 - 22 / 2, height - 42 - 22)

    return await app.renderer.extract.image(container)
  }
  finally {
    container.destroy()
    app.stage.removeChild(container)
  }
})
